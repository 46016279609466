import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Client from 'shopify-buy';
import './stylesheets/Product.css'
import { publicShopifyToken, shopifyDomain } from '../config.js';
import { useShopContext } from '../providers/ShopProvider.js';

const client = Client.buildClient({
  domain: shopifyDomain,
  storefrontAccessToken: publicShopifyToken,
});

const Product = () => {
  const { productId } = useParams();
  /**
   * @type {ReturnType<typeof React.useState<Client.Product>>}
   */
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState(-1)
  const [itemCount, setItemCount] = useState(0)
  const navigate = useNavigate();

  const [checkoutID, setCheckoutID] = useState()
  const shopContext = useShopContext()

  useEffect(() => {
    // Construct the global ID using the numeric ID
    const globalProductId = `gid://shopify/Product/${productId}`;

    setLoading(true)
    shopContext.fetchProductDetails(globalProductId).then((result) => {
      if (!result) {
        setProduct(null)
        return;
      }
      setProduct(result);
    }).finally(() => setLoading(false))
  }, [productId]);


  const renderSizes = () => {
    const sizeOption = product.options.find(option => option.name.toLowerCase() === 'size');
    if (!sizeOption) {
      return null;
    }

    return (
      <div id='prodSizeWrapper' style={{}}>
        <h3>Sizes:</h3>
        {sizeOption.values.map((size, index) => (
          <label key={index} id='prodSize' >
            <input onChange={() => setSelectedSize(index)} checked={selectedSize === index} type='radio' value={index} name='size' />
            {size.value}
          </label>
        ))}
      </div>
    );
  };

  async function addToCart() {
    if (selectedSize < -1) {
      alert("Please select a size")
      return;
    }

    if (itemCount < 1) {
      alert("Please select a quantity")
      return;
    }

    shopContext.addToCart(product.variants[selectedSize].id, itemCount)
      .then((result) => {
        if (result) {
          setSelectedSize(-1)
          setItemCount(0)
        }
      })
  }
  useEffect(() => {
    if (checkoutID) {

    }
  }, [checkoutID])

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className='ProductDisplay'>
      <div style={{}} id='headerProduct'>
        <a onClick={() => navigate('/shop')}>
          <h1 id='ReturnShop'>Return To Shop</h1>
        </a>
      </div>
      {/* <h1 id='prodTitle'>{product.title}</h1> */}
      <div className='ProductDisplay' id='ProductDisplay'>
        <div id='ProductWrapper' style={{  }}>
          {product.images.length > 0 && (
            <img id='prodImg' src={product.images[0].src} alt={product.title} width="400" />
          )}
          <div id='productBatch' style={{  }}>
            <h2>{product.title}</h2>
            <p style={{ fontWeight: 400, fontStyle: 'italic' }}>Description</p>
            <p>{product.description}</p>
            <p id='prodPrice'>{`${product.variants[0].price.amount} ${product.variants[0].price.currencyCode}`}</p>
            {renderSizes()}
            <div style={{alignSelf:'center'}}>
            <button id='Symbolarth' disabled={itemCount === 0} onClick={() => setItemCount(prev => prev - 1)}>-</button>
            <input value={itemCount} onChange={event => setItemCount(parseInt(event.target.value))} />
            <button id='Symbolarth' onClick={() => setItemCount(prev => prev + 1)}>+</button><br />
            </div>
           <button id='AddtoCart' onClick={addToCart}>Add To Cart</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;


// export default function Product() {
//   return (
//     <>
//     </>
//   )
// }
