import React, { useEffect, useState, useRef } from 'react';
import './stylesheets/Home.css';
import { Link, useLocation } from 'react-router-dom';
import bgImg from './assets/bg.png';
import Disc1 from './assets/Disc1.png';
import Disc2 from './assets/Disc2.png';
import Disc3 from './assets/Disc3.png';
import Africa from './assets/Africa.webp';
import Homebackground from './assets/HomeBackground.png';
import Clickhere from './assets/clickhere.png';
import SwipeImg from './assets/swipe.gif';
import gradientimg from './assets/gradient.png';
import getOS from './components/GetOS';
import GlobeComponent, { GlobeDemo } from './components/GlobeComponent';
import Loading from './components/Loading.js';
import { Conditions } from './components/Conditions.js';
import SmallLogo from '../Pages/assets/logo.png'
import LinkButton from './components/LinkButton.js';
import axios from 'axios';
import AlertModal from './components/AlertModal.js';

const FillForm = React.forwardRef((props, ref) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [modalMessage, setModalMessage] = useState()
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  /** @param { React.FormEvent<HTMLFormElement> } event */
  function sendMail(event) {
    event.preventDefault()
    setLoading(true);

    axios.post('https://us-central1-discomisr-d5eb7.cloudfunctions.net/sendEmail', {
      name: event.target.fname.value,
      email: event.target.email.value,
      performanceDate: event.target.dateID.value,
      performanceLocation: event.target.location.value,
      comment: event.target.comment.value
    })
      .then((response) => {
        setLoading(false);
        setModalMessage('Thank you for contacting Disco! We will get back to you shortly.')
        formRef.current.reset();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setModalMessage('An error occurred. Please try again later.')
      })
  }

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const today = new Date();
  today.setDate(today.getDate() + 1); // Add 1 day
  const minDate = today.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format


  return (
    <div ref={ref} className='ContactUs'>
      <div style={{ textAlign: 'center' }} id='TitleContact'>
        <h3>LOOKING TO COLLABORATE?</h3>
        <h4>PLEASE GET IN TOUCH</h4>
      </div>
      <form ref={formRef} onSubmit={sendMail} id='SubmitForm' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <input required placeholder='Name' type="text" id="fname" name="fname" /><br />
        <input required placeholder='Email' type="email" id="email" name="email" /><br />
        {isSmallScreen ? (
          <input required placeholder='Performance Date' type="text" id="dateID" name="dateSmall" />
        ) : (
          <input min={minDate} required placeholder='Performance Date' type="date" id="dateID" name="date" style={{ backgroundColor: 'white' }} />
        )}
        <br />
        <input required placeholder='Performance Location' type="text" id="location" name="location" /><br />
        <input required placeholder='Comment' type="text" id="comment" name="comment" />
      </form>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <button id='SendButton' type='submit' form='SubmitForm'>Send</button>
      </div>
      {loading && <div className="loading-indicator">Loading...</div>}
      <AlertModal
        show={!!modalMessage}
        alertText={modalMessage}
        widthController={50}
        buttonsVisible
        b3={{onClick: () => setModalMessage(null), text: 'OK'}}
        b3bg = {"black"}
        b3c = {"white"}
      />
    </div>
  );
});

const WhereAreWe = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const whereWeAt = document.getElementById('WhereWeAt');
      const offsetTop = whereWeAt.offsetTop;
      const height = window.innerHeight;

      if (scrollTop >= offsetTop - height && scrollTop <= offsetTop + height) {
        const newPosition = (scrollTop - (offsetTop - height)) / height;
        setScrollPosition(Math.min(newPosition, 1));
      } else if (scrollTop > offsetTop + height) {
        setScrollPosition(1);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const topLeftPercent = Math.min(10 + scrollPosition * 90, 100);
  const bottomRightPercent = Math.min(10 + scrollPosition * 45, 50);

  const marginTopValue = `${-scrollPosition * 25}%`;
  const paddingTopValue = `${scrollPosition * 15}%`;

  return (
    <div
      id='WhereWeAt'
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `radial-gradient(circle at bottom right, white ${topLeftPercent}%, black ${bottomRightPercent}%)`,
        paddingTop: '5%',
        marginTop: marginTopValue,
        paddingTop: paddingTopValue

      }}
    >
      <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
        <h3 id='WhereText'>Where are <br /> we now?</h3>
      </div>
      <div id='GlobeWrapper' style={{ width: '50%', padding: 0, margin: 0 }}>
        <GlobeComponent />
      </div>

      {/* <div id='Location' style={{}}> */}
      {/* <img src={Africa} alt="Africa" />
        <h4 id='LocationText'>Cairo, Egypt</h4> */}
      {/* </div> */}
    </div>
  );
};

const Home = ({ scrollToForm }) => {
  const [scrollY, setScrollY] = useState(0);
  const formRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const discs = [Disc1, Disc2, Disc3];
  const discText = ["Studio Photos", "Live Photos", `Albums\n`];
  const discLinks = ["/StudioPictures", "/LivePictures", "/Releases"]
  const location = useLocation();
  const [isXScrollActive, setIsXScrollActive] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const transitionSpeed = windowWidth < 768 ? 5 : 3;
  const os = getOS();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY * 0.60);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollToForm) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToForm]);

  const getBackgroundColor = ({ getMaxScroll, startParameter }) => {
    const maxScroll = getMaxScroll; // Adjust this value to control the length of the transition
    const factor = windowWidth < 768 && scrollY > 25 ? Math.min((scrollY * 10) / maxScroll, 1) : Math.min(scrollY / maxScroll, 1);

    const startColor = [213, 213, 213]; // #d5d5d5
    const endColor = [0, 0, 0]; // black


    const interpolateColor = (start, end, factor) => {
      return start.map((startVal, index) => Math.round(startVal + factor * (end[index] - startVal)));
    };

    const color = interpolateColor(startColor, endColor, factor);
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  const handleDiscClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.DiscDisplay');
      if (element.scrollWidth > element.clientWidth) {
        setIsXScrollActive(true);
      } else {
        setIsXScrollActive(false);
      }
    };

    handleScroll(); // Initial check
    window.addEventListener('resize', handleScroll); // Re-check on resize

    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const adjustConditions = (divisor) => {
    return windowWidth < 768 ? divisor : divisor / 5;
  };

  const conditions = Conditions;

  let WebKitFiller = `rgba(255,255,255,${1 - Math.min(scrollY / 800, 1)}`;

  for (let i = 0; i < conditions.length; i++) {
    const nextThreshold = conditions[i + 1] ? conditions[i + 1].threshold : Infinity;
    if (scrollY > conditions[i].threshold && scrollY <= nextThreshold) {
      WebKitFiller = `rgba(255,255,255,${1 - Math.min(scrollY / conditions[i].divisor, 1)}`;
      break;
    }
  }

  const getScrollValues = (windowWidth) => {
    const conditions = [
      { condition: os === 'Windows' && windowWidth > 1024 && windowWidth <= 1512, value: 170 },
      { condition: windowWidth > 1024 && windowWidth < 1280, value: 205 },
      { condition: windowWidth >= 1280 && windowWidth <= 1512, value: 190 },
      { condition: windowWidth === 1024, value: 300 },
      { condition: os === 'Windows' && windowWidth > 1512 && windowWidth <= 1920, value: 170 },
      { condition: os !== 'Windows' && windowWidth > 1512 && windowWidth <= 1920, value: 220 },
      { condition: windowWidth < 768, value: 25 },
      { condition: windowWidth > 1920 && scrollY > 0 && scrollY < 200, value: 310 },
      { condition: windowWidth > 1920 && scrollY > 200, value: 300 },
    ];
    for (const { condition, value } of conditions) {
      if (condition) return value;
    }
    if (windowWidth > 768 && windowWidth < 1024) {
      if (scrollY > 0 && scrollY <= 50) return 510;
      if (scrollY > 50 && scrollY <= 100) return 510;
      if (scrollY > 100 && scrollY <= 150) return 290;
      if (scrollY > 150 && scrollY <= 175) return 320;
      if (scrollY > 175 && scrollY <= 200) return 282.5;
      if (scrollY > 200 && scrollY <= 235) return 267.5;
      if (scrollY > 235) return 255;
    }
    return 275;
  };

  const transitionDuration = scrollY < 30 ? '0s' : (windowWidth < 768 ? '2.0s' : '0.1s'); //Transition duration for the opacity

  const containerRef = useRef(null);
  useEffect(() => {
    const handleScroll = (event) => {
      const container = containerRef.current;
      if (container.scrollTop >= 350) {
        window.scrollBy({
          top: container.scrollTop - 350,
          behavior: 'smooth'
        });
        container.scrollTop = 350;
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let getMarginTop = -Math.min(windowWidth < 1024 ? 100 : 750, scrollY * (windowWidth < 1024 ? 1 : 3));
  let getAboutTextWrap = windowWidth < 1024 ? '80%' : '90%';
  if (windowWidth > 1920) {
    getMarginTop = - Math.min(1225, scrollY * 5)
    getAboutTextWrap = "62.5%";
  }


  return (
    <div className='HomePage' style={{ backgroundColor: getBackgroundColor({ getMaxScroll: 400 }), overflowX: 'hidden' }}>
      {/* <h1 style={{ position: 'fixed', color: 'white', fontSize: 32, zIndex: 9999 }}>{scrollY}</h1>
      <h1 style={{ position: 'fixed', color: 'white', fontSize: 32, zIndex: 9999, top: '5%' }}>{windowWidth}</h1> */}
      {/* <div id='PositionLink'>
          <LinkButton/>
      </div> */}
      <div id='LandingPage' className={windowWidth < 768 ? 'mobile-bg' : ''} style={{}} ref={containerRef}>
        {windowWidth >= 768 && (
          <img
            src={bgImg}
            alt="Landing"
            style={{ transform: windowWidth < 768 ? `translateY(${scrollY}px)` : `translateY(${scrollY * .25}px)`, offsetPosition: '0 0' }}
          />
        )}
        <div className='overlay' style={{ opacity: ((scrollY * transitionSpeed) / window.innerHeight) * transitionSpeed * `${windowWidth < 1024 ? 0.50 : 0.50}` }}></div>
        <div style={{ position: 'absolute', right: 10, display: windowWidth < 768 ? '' : 'none' }}>
          <img id='LogoStyle' src={SmallLogo} />
        </div>
      </div>
      <div id='LandAboutWrapper' >
        <div id='LandingAbout' style=
          {{
            backgroundColor: windowWidth > 768 ? getBackgroundColor({ getMaxScroll: `${getScrollValues(windowWidth)}` }) : 'transparent', zIndex: 11, opacity: ((scrollY * transitionSpeed * 2.5) / (window.innerHeight)) * transitionSpeed * `${windowWidth < 1024 ? 0.20 : 1}`,
            transition: `opacity ${transitionDuration} ease-out`, position: 'relative', width: '100%', height: windowWidth < 1024 ? '80vh' : '100%',
            scrollBehavior: 'smooth', padding: windowWidth < 1024 ? 0 : 100, paddingTop: windowWidth < 1024 ? 0 : 300,

          }}>
          <div id='WidthTextWrap'>
            <h4 style={{
              flex: 1, width: getAboutTextWrap, marginTop: getMarginTop,
              color: 'rgba(255, 255, 255, 1)', padding: '2.5%', paddingTop: windowWidth < 768 ? '50%' : '12.5%'
            }} id='AboutTextWrap'>

              <span className="bold-text linear-one"
                style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}>Disco Misr, </span>
              <span className='linear-one' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> an Egyptian sensation</span>
              <span className='linear-two' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> revolutionizes the music scene by </span>
              <span className='linear-three' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}>infusing contemporary Arab tunes</span>
              <span className='linear-four' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> with their signature style, creating</span>
              <span className='linear-five' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> an immersive audiovisual</span>
              <span className='linear-six' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> experience. Feature two DJs and</span>
              <span className='linear-seven' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> by our captivating visuals and</span>
              <span className='linear-eight' style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}> upbeat grooves coming together </span>
              <span className="linear-two-gold" style={{ WebkitTextFillColor: WebKitFiller, color: 'rgba(255, 255, 255, 1)' }}>
                to create an unforgettable dance floor experience. Get ready to groove to the exhilarating sounds of Disco Misr!
              </span>
            </h4>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: -Math.min(windowWidth < 768 ? 200 : 0, scrollY * (windowWidth < 768 ? 1 : 5)), transition: '.4s' }}>
            <div id='HomeAbout' style={{}}>
              <Link to="/about" style={{ textDecoration: 'none', margin: 0 }}>Know More</Link>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          </div>
        </div>
        <div style={{ paddingBottom: '50vh' }}>
          <div className='DiscDisplay'>
            {discs.map((disc, index) => (
              <Link to={`${discLinks[index]}`} key={index} className="CDWrapper" style={{ textDecoration: 'none' }} onClick={() => handleDiscClick(index)}>
                <h4 id='cdWrapText' style={{ textAlign: 'center' }} className={activeIndex === index ? 'active' : ''}>{discText[index]}</h4>
                <img
                  src={disc}
                  alt={`Disc ${index + 1}`}
                  style={{}}
                  id='discIDcursor'
                />
                {/* {index === 0 && (
              
              )} */}
              </Link>
            ))}
          </div>
          <div id='swipeText' style={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: '2%' }}>
            <img src={SwipeImg} style={{ width: '2.5%', margin: 0, padding: 0, alignSelf: 'center' }} alt='SwiperIcon' />
            <h4 id='outerh4' style={{ fontWeight: 400, opacity: 0.75, fontStyle: 'italic', fontSize: 14, WebkitTextStroke: 0, color: 'white', margin: 0, textAlign: 'center', alignSelf: 'center' }}>Swipe</h4>
          </div>
        </div>
      </div>
      <WhereAreWe />
      <FillForm ref={formRef} />
    </div>
  );
};

export default Home;
