import React, { useRef, useEffect, useState } from 'react';
import './stylesheets/Releases.css';
import Logo from './assets/logo.png';
import { FaSpotify, FaAngellist, FaYoutube, FaApple } from 'react-icons/fa';
import { ReleasesData } from './components/ReleasesData';

const SocialOverlay = ({ links }) => {
  return (
    <div className="social-icons">
      {links.spotify && (
        <a href={links.spotify} target="_blank" rel="noopener noreferrer">
          <FaSpotify className="icon" />
        </a>
      )}
      {links.anghami && (
        <a href={links.anghami} target="_blank" rel="noopener noreferrer">
          <FaAngellist className="icon" />
        </a>
      )}
      {links.youtube && (
        <a href={links.youtube} target="_blank" rel="noopener noreferrer">
          <FaYoutube className="icon" />
        </a>
      )}
      {links.appleMusic && (
        <a href={links.appleMusic} target="_blank" rel="noopener noreferrer">
          <FaApple className="icon" />
        </a>
      )}
    </div>
  );
};
const ReleaseComponent = ({ getImg, getTitle, index, isActive, isLeft, isRight, onClick, overlayVisible, links, isWideScreen, isScrolling }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = getImg;
    img.onload = () => {
      setImgLoaded(true);
    };
  }, [getImg]);

  let className = 'release-component';
  if (isActive) className += ' release-active';
  if (isLeft) className += ' release-left';
  if (isRight) className += ' release-right';

  const rotationStyle = 'rotate(0deg)';
  const scaleStyle = isActive ? 'scale(1)' : 'scale(0.75)';
  const zStyle = isActive ? 10 : 0;

  return (
    <div className={className} onClick={isActive ? onClick : null} >
      <div className={`image-container ${imgLoaded ? 'loaded' : 'loading'}`} style={{ transform: `${rotationStyle} ${scaleStyle}`, transition:'.2s ease-in' , zIndex: `${zStyle}` }}>
        {!imgLoaded && <div className="image-placeholder"></div>}
        <img src={getImg} alt={getTitle} onLoad={() => setImgLoaded(true)} className={imgLoaded ? 'visible' : 'hidden'} />
      </div>
      {overlayVisible && <div className="overlay"><SocialOverlay links={links} /></div>}
      <h4 id='TitleRelease'>{getTitle}</h4>
      {index !== 0 && index !== 10 && <div className="index-number">{index}</div>}
    </div>
  );
};


export default function Releases() {
  const releases = ReleasesData

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const wrapperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1024);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    const scrollLeft = wrapperRef.current.scrollLeft;
    const childWidth = wrapperRef.current.children[0].offsetWidth + 30; // include gap

    const index = Math.round(scrollLeft / childWidth);
    setActiveIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    wrapper.addEventListener('scroll', handleScroll);

    const handleScrollStart = () => {
      setIsScrolling(true);
    };

    const handleScrollEnd = () => {
      setIsScrolling(false);
    };

    let scrollTimeout;
    const handleScrollDebounce = () => {
      clearTimeout(scrollTimeout);
      handleScrollStart();
      scrollTimeout = setTimeout(handleScrollEnd, 150); // adjust timeout as needed
    };

    wrapper.addEventListener('scroll', handleScrollDebounce);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
      wrapper.removeEventListener('scroll', handleScrollDebounce);
    };
  }, []);

  useEffect(() => {
    setOverlayVisible(false);
  }, [activeIndex]);

  const handleClick = () => {
    setOverlayVisible(!overlayVisible);
  };

  const handlePageClick = (event) => {
    if (!event.target.closest('.release-component')) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    if (overlayVisible) {
      window.addEventListener('click', handlePageClick);
    } else {
      window.removeEventListener('click', handlePageClick);
    }

    return () => {
      window.removeEventListener('click', handlePageClick);
    };
  }, [overlayVisible]);

  const scrollLeft = () => {
    const wrapper = wrapperRef.current;
    // const childWidth = wrapper.children[0].offsetWidth + 30; // include gap
    const childWidth = (windowWidth < 1024 ? wrapper.children[0].offsetWidth + 0 : wrapper.children[0].offsetWidth + 30); //Transition duration for the opacity
    wrapper.scrollBy({ left: -childWidth, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const wrapper = wrapperRef.current;
    // const childWidth = wrapper.children[0].offsetWidth + 30; // include gap
    const childWidth = (windowWidth < 1024 ? wrapper.children[0].offsetWidth + 0 : wrapper.children[0].offsetWidth + 30); //Transition duration for the opacity

    wrapper.scrollBy({ left: childWidth, behavior: 'smooth' });
  };

  return (
    <div id='ReleasePage' style={{ backgroundColor: '#a2a2a250' }}>
      <img id='LogoImg' src={Logo} alt="Logo" />
        <h3 style={{position:'fixed', fontWeight:400, fontStyle:'italic'}}>Swipe!</h3>
      <div id='ReleaseWrapper' ref={wrapperRef}>
        {isWideScreen ? (
          releases.map((release, index) => (
            <ReleaseComponent
              key={index}
              getImg={release.img}
              getTitle={release.title}
              index={index}
              isActive={index === activeIndex + 1}
              isLeft={index === activeIndex}
              isRight={index === activeIndex + 2}
              onClick={handleClick}
              // overlayVisible={overlayVisible && index === activeIndex + 1}
              links={release.links || {}}
              isWideScreen={isWideScreen}
              isScrolling={isScrolling}
            />
          ))
        ) : (
          releases.map((release, index) => (
            <ReleaseComponent
              key={index}
              getImg={release.img}
              getTitle={release.title}
              index={index}
              isActive={index >= 6 ? index === activeIndex + 1 : index === activeIndex}
              // isLeft={index === activeIndex - 1 }
              // isRight={index === activeIndex + 2}
              onClick={handleClick}
              // overlayVisible={overlayVisible && index >= 6 ? index === activeIndex + 1 : index === activeIndex}
              links={release.links || {}}
              isWideScreen={isWideScreen}
              isScrolling={isScrolling}
            />
          ))
          
        )}
      </div>
      <div className="arrows">
        <button onClick={scrollLeft} className="arrow left-arrow">←</button>
        <button onClick={scrollRight} className="arrow right-arrow">→</button>
      </div>
    </div>
  );
}