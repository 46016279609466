import React from 'react';
import './Pages/stylesheets/Footer.css';
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div id='leftside'>
        <h3 style={{ marginRight: '25%' }}>@Disco Misr</h3>
        <h3>All Rights Reserved</h3>
      </div>
      <div id='MiddleFooter' style={{ width: '25%', display: 'flex', flexDirection:'column', alignItems:'center', justifyContent: 'center', textAlign:'center'}}>
        <h4 style={{textAlign:'center', fontSize:'1svh'}}>Our Socials</h4>
      <div style={{display:'flex', flexDirection:'row',  padding:0, margin:0}}>
        <a href="https://www.facebook.com/discomisr" target='_blank' className='social-icon' style={{marginLeft:'-26.25%'}}>
          <FaFacebookF size={17} />
        </a>

        <a href="https://www.instagram.com/discomisr" target='_blank' className='social-icon' style={{marginRight:'26.25%', marginLeft:'26.25%'}}>
          <FaInstagram size={18} />
        </a>

        <a href="https://www.youtube.com/discomisr" target='_blank' className='social-icon' style={{}}>
          <IoLogoYoutube size={18} />
        </a>
      </div>

      </div>
      <div id='rightside'>
        <h3>@discomisr</h3>
        <h3 style={{ marginLeft: '25%' }}>@discomisr</h3>
      </div>
    </footer>
  );
}

export default Footer;
